$(function () {

    function submitForm() {
        // Initiate Variables With Form Content
        var name = $("#name").val();
        var email = $("#email").val();
        var phone = $("#phone").val();
        var message = $("#message").val();

        $.ajax({
            type: "POST",
            url: "contact-form.php",
            data: {
                name: name,
                email: email,
                message: message,
                phone: phone
            }
        })
        ;
    }

    $("#contactForm").on("submit", function (event) {
        event.preventDefault();

        if (grecaptcha.getResponse() == "") {
         event.preventDefault();
         $('.alert-danger').show();
         }

         else {

        $('.alert-danger').hide();

        submitForm();

        $(this).hide();

        $('.alert-success').show();

        $(this).find('input, textarea').val("");

        grecaptcha.reset();

        $('.alert-success').delay(2000).hide(0);

        $(this).delay(2500).show(0);

      }
    });

});
